import { IconButton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { RoutePaths } from '../../pages/routes'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface ArrowBackProps {
    path: RoutePaths
}

const ArrowBack = ({path}: ArrowBackProps): JSX.Element => {
    return (
        <IconButton
        sx={{mr: 1}}
            size="large"
            component={Link}
            to={path}
        >
            <ArrowBackIosNewIcon />
        </IconButton>
    )
}

export default ArrowBack