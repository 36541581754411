import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import TranslateIcon from '@mui/icons-material/Translate';
import { Button, IconButton, Menu, MenuItem, Typography, Box, Divider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { RoutePaths, translateRoutePaths } from '../../../../../pages/routes';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../../i18n/i18n.constants';
import { ToggleLanguage } from '../../../../../components';


/**
 * All related pages user might be ablee to navigate
 * @returns 
 */
const MenuPages = (): JSX.Element => {

    const { t } = useTranslation(namespaces.app)
    const location = useLocation();
    const currentPath = location.pathname as RoutePaths;
    console.log(currentPath)
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (): void => {
        setAnchorElNav(null);
    };

    const pages = [
        RoutePaths.About,
        RoutePaths.Prices,
        RoutePaths.Reviews,
        RoutePaths.LearnMore,
        // Temporary
    ];

    return (
        <>
            {/* MOBILE */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }}}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="primary"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    {pages.map((page: RoutePaths): JSX.Element => (
                        <MenuItem selected={page === currentPath} component={Link} to={page} key={page} onClick={handleCloseNavMenu}>
                            <Typography  textAlign="center">{t(translateRoutePaths(page))}</Typography>
                        </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem onClick={handleCloseNavMenu}>
                        <TranslateIcon color="primary" sx={{ mr: 1 }} />
                        <ToggleLanguage />
                    </MenuItem>
                </Menu>
            </Box>
            {/* DESKTOP */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                {pages.map((page) => (
                    <Button          
                        component={Link} to={page}
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, display: 'block' }}
                    >
                        {t(translateRoutePaths(page))}
                    </Button>
                ))}
            </Box>
        </>
    )
}

export default MenuPages