import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RegisterForm } from '../../types/RegisterPage'

// Define a type for the slice state
interface RegisterState {
    registerForm: RegisterForm
    activeStep: number
}

// Define the initial state using that type
const initialState: RegisterState = {
    registerForm: {
        firstName: '',
        secondName: '',
        lastName: '',
        secondLastName: '',
        gender: '',
        birthDate: '',
        documentType: 1, // Cedula
        documentNumber: '',
        email: '',
        countryCode: '57', // Default Colombia
        mobile: '',
        landline: '',
        fullAddress: '',
        habeasData: false,
        password: '',
        repeatPassword: ''
    },
    activeStep: 0,
}

export const registerSlice = createSlice({
    name: 'register',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setRegisterForm: (state, action: PayloadAction<RegisterForm>) => {
            state.registerForm = action.payload
        },
        handleNext: (state) => {
            const newValue = state.activeStep + 1
            state.activeStep = newValue
        },
        handleBack: (state) => {
            if (state.activeStep === 0) return
            state.activeStep = state.activeStep - 1
        },
        resetRegister: (state) => {
            state.activeStep === 0
            state.registerForm = {
                firstName: '',
                secondName: '',
                lastName: '',
                secondLastName: '',
                gender: '',
                birthDate: '',
                documentType: 1, // Cedula
                documentNumber: '',
                email: '',
                countryCode: '57', // Default Colombia
                mobile: '',
                landline: '',
                fullAddress: '',
                habeasData: false,
                password: '',
                repeatPassword: ''
            }
        }
    },
})

export const {
    setRegisterForm,
    handleNext,
    handleBack,
    resetRegister
} = registerSlice.actions


export default registerSlice.reducer