import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Logo, MenuPages } from './components';
import AccountMenu from './components/AccountMenu/AccountMenu';
import { Box, CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { ToggleLanguage } from '../../../components';
import { useAppSelector } from '../../../redux/hooks';


/**
 * Header. It will contain all the navigation from pages to account pages.
 * @returns 
 */
const Header = (): JSX.Element => {

  const loadingUser = useAppSelector(state => state.app.loadingUser)
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <AppBar variant="outlined" position="fixed"
      sx={(theme: Theme) => ({
        background: theme.palette.background.paper
      })}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Logo />
          <Box sx={{
            display: 'flex', alignItems: 'center', flex: {
              xs: 0,
              md: 1
            }
          }}>
            <MenuPages />
            {
              isMd && <ToggleLanguage />
            }
            {
              loadingUser
                ? <CircularProgress color="primary" />
                : <AccountMenu />
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;