import { Box, Button, IconButton, Menu, MenuItem, Theme, useMediaQuery } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../pages/routes';
import PersonIcon from '@mui/icons-material/Person';

/**
 * Componet that shows loggin menu. If user login this will dissapear.
 * @returns 
 */
const LoginArea = (): JSX.Element => {
    const { t } = useTranslation(namespaces.app)
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    return (
        <>
            {
                isMd
                    ? <Box
                        sx={{
                            display: 'flex', flexDirection: {
                                xs: 'column',
                                sm: 'row'
                            },
                            py: 0,

                        }}>
                        <Button
                            component={Link}
                            to={RoutePaths.Login}
                            sx={{ mr: 1 }}
                            size={isMd ? 'small' : 'large'}
                            variant="text"
                            startIcon={<LoginIcon />}>{t('routeNames.login')}
                        </Button>
                        <Button
                            component={Link}
                            to={RoutePaths.Register}
                            size={isMd ? 'small' : 'large'}

                            variant="contained"
                            startIcon={<PersonAddIcon />}>{t('routeNames.register')}
                        </Button>
                    </Box>
                    : <div>
                        <IconButton
                            color="primary"
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <PersonIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <Button
                                    component={Link}
                                    to={RoutePaths.Login}
                                    sx={{ mr: 1 }}
                                    size={isMd ? 'small' : 'large'}
                                    variant="text"
                                    startIcon={<LoginIcon />}>{t('routeNames.login')}
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Button
                                    component={Link}
                                    to={RoutePaths.Register}
                                    size={isMd ? 'small' : 'large'}

                                    variant="contained"
                                    startIcon={<PersonAddIcon />}>{t('routeNames.register')}
                                </Button>
                            </MenuItem>
                        </Menu>
                    </div>
            }
        </>


    )
}

export default LoginArea