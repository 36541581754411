import React from 'react'
import ListIcon from '@mui/icons-material/List';
import { Box, Typography } from '@mui/material';

interface EmptyListProps {
    text: string
}

const EmptyList = ({text} : EmptyListProps): JSX.Element => {
    return (
        <Box sx={{
            border: '1px solid',
            borderColor: 'divider',
            minHeight: 200,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
        }}>  
            <ListIcon sx={{ fontSize: 75, color: 'divider' }} />
            <Typography color='text.disabled'>{text}</Typography>
        </Box>
    )
}

export default EmptyList