import { SaludToolsApiRoutes } from "../constants"
import { ApiResponse, SaludToolsAuthorizationError } from "../types/Api"
import ApiError from "../types/Api/ApiError"
import { SaludToolsAuthorizationRequest } from "../types/services/ApiServices/request"
import { SaludToolsAuthorizationResponse } from "../types/services/ApiServices/response"
import { isHttpError, isSaludToolsAuthorizationError } from "./ApiError"


/**
 * Services for all APIs calls.
 */
export class ApiServices {

    // In the future here we will handle all GET , POST , PUT, DELETE , PATCH. 

    /**
     * Function to get Authorization to use SaludTools.
     * @returns Authorization token
     */
    async saludToolsAuthorization(): Promise<string | ApiError> {
        try {
            const { REACT_APP_SALUDTOOLS_KEY, REACT_APP_SALUDTOOLS_SECRET } = process.env

            if (REACT_APP_SALUDTOOLS_KEY == null || REACT_APP_SALUDTOOLS_SECRET == null) {
                return ''
            }

            const authorization: SaludToolsAuthorizationRequest = {
                key: REACT_APP_SALUDTOOLS_KEY,
                secret: REACT_APP_SALUDTOOLS_SECRET,
            }

            const settings = {
                method: "POST",
                body: JSON.stringify(authorization),
                headers: {
                    "Content-Type": "application/json",
                },
            }
            const request = await fetch(SaludToolsApiRoutes.authorization, settings)

            const response = await request.json() as SaludToolsAuthorizationResponse | SaludToolsAuthorizationError

            if (isSaludToolsAuthorizationError(response)) {
                const { error, status } = response
                const newError: ApiError = {
                    code: status,
                    error,
                }
                return newError
            }

            const { access_token } = response

            return `Bearer ${access_token}`

        } catch (e) {
            const error = e as Error
            const isError: ApiError = {
                error: error.message,
                code: 500
            }
            return isError
        }
    }

    /**
     * Function to get tokens saved on LocalStorage
     * @returns 
     */
    getTokens(): { accessToken: string, refreshToken: string } | undefined {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (accessToken == null || refreshToken == null) {
            return undefined
        }
        return { accessToken, refreshToken }
    }

    /**
 * looks for new access and refresh token in the headers
 * if yes, local storage gets updated with new tokens
 * @param response fetch response
 */
    renewTokenFromHeaders = (response: Response): void => {
        let newAccessToken: string | null
        let newRefreshToken: string | null

        if (response.headers.has("x-access-token")) {
            newAccessToken = response.headers.get("x-access-token")!
            localStorage.setItem('accessToken', newAccessToken);
        } if (response.headers.has("x-refresh-token")) {
            newRefreshToken = response.headers.get("x-refresh-token")!
            localStorage.setItem('refreshToken', newRefreshToken);
        }
    }

    async get<TResp extends ApiResponse, TReq = undefined,>(url: string, requestData?: TReq): Promise<TResp | ApiError> {
        try {
            const tokens = this.getTokens();

            const settings = {
                method: "GET",
                body: requestData ? JSON.stringify(requestData) : undefined,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokens?.accessToken ?? ''}`,
                    "Refresh-Token": `${tokens?.refreshToken ?? ''}`
                },
            };

            const request = await fetch(url, settings);

            this.renewTokenFromHeaders(request)

            const response = await request.json() as TResp;

            if (isHttpError(response.code)) {
                return {
                    error: response.message,
                    code: response.code
                }
            }

            return response;
        } catch (e) {
            const error = e as Error;
            const isError: ApiError = {
                error: error.message,
                code: 500
            };
            return isError;
        }
    }

    async post<TResp extends ApiResponse, TReq = null >(url: string, requestData?: TReq): Promise<TResp | ApiError> {
        try {
            const tokens = this.getTokens();

            const settings = {
                method: "POST",
                body: requestData ? JSON.stringify(requestData) : undefined,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokens?.accessToken ?? ''}`,
                    "Refresh-Token": `${tokens?.refreshToken ?? ''}`
                },
            };

            const request = await fetch(url, settings);

            this.renewTokenFromHeaders(request)

            const response = await request.json() as TResp;
          
            if (isHttpError(response.code)) {
                return {
                    error: response.message,
                    code: response.code
                }
            }
           
            return response;
        } catch (e) {
            const error = e as Error;
            const isError: ApiError = {
                error: error.message,
                code: 500
            };
            return isError;
        }
    }

    async put<TResp extends ApiResponse, TReq,>(url: string, requestData?: TReq): Promise<TResp | ApiError> {
        try {
            const tokens = this.getTokens();

            const settings = {
                method: "PUT",
                body: requestData ? JSON.stringify(requestData) : undefined,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokens?.accessToken ?? ''}`,
                    "Refresh-Token": `${tokens?.refreshToken ?? ''}`
                },
            };

           
            const request = await fetch(url, settings);
         
            this.renewTokenFromHeaders(request)

            const response = await request.json() as TResp;

            if (isHttpError(response.code)) {
                return {
                    error: response.message,
                    code: response.code
                }
            }

            return response;
        } catch (e) {
            const error = e as Error;
            const isError: ApiError = {
                error: error.message,
                code: 500
            };
            return isError;
        }
    }

    async patch<TResp extends ApiResponse, TReq,>(url: string, requestData?: TReq): Promise<TResp | ApiError> {
        try {
            const tokens = this.getTokens();

            const settings = {
                method: "PATCH",
                body: requestData ? JSON.stringify(requestData) : undefined,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokens?.accessToken ?? ''}`,
                    "Refresh-Token": `${tokens?.refreshToken ?? ''}`
                },
            };

            const request = await fetch(url, settings);

            this.renewTokenFromHeaders(request)

            const response = await request.json() as TResp;

            if (isHttpError(response.code)) {
                return {
                    error: response.message,
                    code: response.code
                }
            }

            return response;
        } catch (e) {
            const error = e as Error;
            const isError: ApiError = {
                error: error.message,
                code: 500
            };
            return isError;
        }
    }

    async delete<TResp extends ApiResponse, TReq = null,>(url: string, requestData?: TReq): Promise<TResp | ApiError> {
        try {
            const tokens = this.getTokens();

            const settings = {
                method: "DELETE",
                body: requestData ? JSON.stringify(requestData) : undefined,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokens?.accessToken ?? ''}`,
                    "Refresh-Token": `${tokens?.refreshToken ?? ''}`
                },
            };

            const request = await fetch(url, settings);

            this.renewTokenFromHeaders(request)

            const response = await request.json() as TResp;

            if (isHttpError(response.code)) {
                return {
                    error: response.message,
                    code: response.code
                }
            }

            return response;
        } catch (e) {
            const error = e as Error;
            const isError: ApiError = {
                error: error.message,
                code: 500
            };
            return isError;
        }
    }
}