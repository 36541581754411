import { ApiRoutes } from "../constants"
import { ApiError, ApiResponse } from "../types/Api"
import { ICreateSessionRequest } from "../types/services/SessionController/request"
import { CreateSessionResponse } from "../types/services/SessionController/response"
import { ApiServices } from "./ApiServices"


/**
 * Service that will talk to Authentication controller.
 */
class SessionController extends ApiServices{

    /**
     * Function to create a session after Login 
     * @param createSessionRequest 
     * @returns 
     */
    async createSession(createSessionRequest: ICreateSessionRequest): Promise<CreateSessionResponse | ApiError> {
       
        const request = await this.post<CreateSessionResponse, ICreateSessionRequest>(`${ApiRoutes.SESSION_CONTROLLER}`,createSessionRequest)

        return request
    }

    async deleteSession() : Promise<ApiResponse | ApiError> {
        
        const request = await this.delete(ApiRoutes.SESSION_CONTROLLER)

        return request
    }
}

export const sessionController = new SessionController()