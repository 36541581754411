import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Appointment, Patient } from '../../types/SaludTools'
import { NydUser, NydUserReview } from '../../types/NefrologiaYDialisis'
import { utilityServices } from '../../services/UtlityServices'


// Define a type for the slice state
interface UserState {
    /**
     * Saludtools Patient Profile information
     */
    saludToolsProfile?: Patient

    /**
     * Current User connected.
     */
    nydProfile?: NydUser

    /**
     * Undefined means, has not done any action question or appointment, so doesnt have a review. 
     */
    nydUserReview?: NydUserReview

    /**
     * Next 6 Appointments
     */
    nextAppointments?: Appointment[]

    /**
     * TODO - Temporary SHOULD BE IN BACKEND
     */
    allowDomiciliary: boolean
}

// Define the initial state using that type
const initialState: UserState = {
    saludToolsProfile: undefined,
    nydProfile: undefined,
    nextAppointments: undefined,
    nydUserReview: undefined,
    allowDomiciliary: false
}

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setSaludtoolsProfile: (state, action: PayloadAction<Patient>) => {
            state.saludToolsProfile = action.payload
        },
        setNydProfile: (state, action: PayloadAction<NydUser | undefined>) => {
            if (action.payload != null) {
                state.allowDomiciliary = utilityServices.domiciliaryAllow(action.payload.fullAddress)
            }
            state.nydProfile = action.payload


        },
        setNydUserReview: (state, action: PayloadAction<NydUserReview | undefined>) => {
            state.nydUserReview = action.payload
        },
        setNextAppointments: (state, action: PayloadAction<Appointment[]>) => {
            state.nextAppointments = action.payload
        },
        resetUserSliceValues: (state) => {
            state.nydProfile = undefined
            state.nextAppointments = undefined,
                state.saludToolsProfile = undefined
            state.nydUserReview = undefined
        }
    },
})

export const {
    setSaludtoolsProfile,
    setNydProfile,
    setNextAppointments,
    resetUserSliceValues,
    setNydUserReview
} = userSlice.actions


export default userSlice.reducer