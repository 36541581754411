export const projectRules = {
    reviewBox: {
        textReviewLimit: 500,
    },
    askYourDoctor: {
        questionSubjectLimit: 20,
        questionDescriptionLimit: 500,
    },
    booking:{
        /**
         * In Hours
         */
        minHour: 7,
        /**
         * In Hours
         */
        maxHour: 18,
        /**
         * In minutes
         */
        interval: 45,
        /**
         * Minimum Day to book
         */
        minDayToBook: 1,
        /**
         * Max Day in Advance to book
         */
        maxDayToBook: 90
    }
}