export const namespaces = {
  pages: {
   //static
    home: "pages.home",
    login: "pages.login",
    register: "pages.register",
    passwordRecovery: "pages.passwordRecovery",
    about: "pages.about",
    learnMore: "pages.learnMore",
    reviews: "pages.reviews",
    verifyEmail: "pages.verifyEmail",
    passwordReset: 'pages.passwordReset',
    confirmation: 'pages.confirmation',

    //user
    account: "pages.account",
    booking: "pages.booking",
    dashboard: "pages.dashboard",
    askYourDoctor: "pages.askYourDoctor",
    creditStore: "pages.creditStore",

    //admin
    adminUserList: "pages.adminUserList",
    adminUserReviews: "pages.adminUserReviews",
    adminUserQuestions: "pages.adminUserQuestions",
    adminDashboard: "pages.adminDashboard",
    adminUserProfile: "pages.adminUserProfile",
    adminCalendarRules: "pages.adminCalendarRules"
  },
  app: "app",
  components: "components"
};

export const languages = {
  es: "es",
  en: "en",
};