const NefrologiaYDialisisSasInfo = {
    /**
     * Legal name of company.
     */
    legalName: 'Nefrología y Diálisis SAS',

    /**
     * Name of company.
     */
    name: 'Nefrología y Diálisis',

    /**
     * Address of company
     */
    address: 'Carrera 6 #5-161 Centro medico Prisma. Consultorio 606, Provincia de Cartagena, Bolívar, Colombia',
    /**
     * Company Email.
     */
    email: 'nefrologiaydialisis@yahoo.es',

    /**
     * Support email. This will be the developer.
     */
    supportEmail: 'soporte@nefrologoenlinea.com',
    /**
     * Legal Identification.
     */
    nit: '806.004.236-3',
    /**
     * Establishment line phone.
     */
    phone: '+57 (5) 65527130',
    /**
     * Establishment mobile phone
     */
    mobile: '(320) 5488-023',
    /**
     * WhatsApp number. Use only for connecting to WhatsApp
     * */
    whatsApp: '+57 320 548 8023',
    /**
     * Code of Colombia.
     */
    countryCode: 57,
    /**
     * Facebook social media URL
     */
    facebookURL: 'https://www.facebook.com/nydcein.com.co',
    /**
     * Instagram social media URL
     */
    instagramURL: 'https://www.instagram.com/nefrologiaydialisis/',
    /**
     * linkedIn social media URL
     */
    linkedInURL: 'https://www.linkedin.com/company/nydcein/about/'
}

export default NefrologiaYDialisisSasInfo