import React from 'react'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import NefrologiaYDialisisLogo from '../../.././../../assets/images/logoNyd.png'
import { NefrologiaYDialisisSasInfo } from '../../../../../constants';
import { RoutePaths } from '../../../../../pages/routes';

/**
 * Logo of Comapny.
 * @returns 
 */
const Logo = (): JSX.Element => {
    return (
        <Button
            component={Link}
            to={RoutePaths.Home}
            sx={{
                '& > img': {
                    objectFit: 'contain',
                    maxHeight: '60px',
                }
            }}>
            <img src={NefrologiaYDialisisLogo} alt={`${NefrologiaYDialisisSasInfo.legalName} Logo.`} />
        </Button>
    )
}

export default Logo