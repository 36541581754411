import { Box, useTheme } from '@mui/material'
import React from 'react'



interface CircleScoreProps {
    score?: number, 
    setSize?: number
}

const CircleScore = ({score, setSize} : CircleScoreProps) : JSX.Element => {



const theme = useTheme()

const selectColor = () : string => {
    if( score == null) {
        return theme.palette.divider
    }
    switch(true) {
        case score >= 8.5: {
        return theme.palette.success.main

        }
        case score >= 6: {
        return theme.palette.warning.main

        }
        default: {
        return theme.palette.error.main
        }
          
    }
}

const selectBorderColor = () : string => {
    if( score == null) {
        return theme.palette.divider
    }
    switch(true) {
        case score >= 8.5: {
        return theme.palette.success.light

        }
        case score >= 6: {
        return theme.palette.warning.main

        }
        default: {
        return theme.palette.error.dark
        }
          
    }
}


    
    const SIZE = setSize ? setSize : 150
    

  return (
    <Box 
    sx={{
      
        width: SIZE, 
        height: SIZE, 
        borderRadius: '50%', 
        border:'4px solid', 
        borderColor: selectBorderColor(),
        color: selectColor(),
        fontSize: SIZE / 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    }}
        >
                {
                    score ? score : '?'
                }
    </Box>
  )
}

export default CircleScore