import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface AppState {
    selectedAppointment?: string
}

// Define the initial state using that type
const initialState: AppState = {
    selectedAppointment: undefined
}

export const appSlice = createSlice({
    name: 'dashboard',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setSelectedAppointment: (state, action: PayloadAction<string | undefined>) => {
           state.selectedAppointment = action.payload
        },
    },
})

export const {
   setSelectedAppointment
} = appSlice.actions


export default appSlice.reducer