import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { NydUserQuestion } from '../../types/NefrologiaYDialisis'

// Define a type for the slice state
interface AskYourDoctorState {
    
    /**
     * All questions from a user
     */
    allQuestions: NydUserQuestion[]
}

// Define the initial state using that type
const initialState: AskYourDoctorState = {
    allQuestions: []
}

export const appSlice = createSlice({
    name: 'askYourDoctor',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAllquestions: (state, action: PayloadAction<NydUserQuestion[]>) => {
           state.allQuestions = action.payload
        },
        updateQuestion: (state, action: PayloadAction<NydUserQuestion>) => {
            const oldArray = state.allQuestions
            const findIndex = oldArray.findIndex(item => item._id === action.payload._id)
            if(findIndex < 0) return
            state.allQuestions[findIndex] = action.payload
         },
         updateAllQuestion: (state, action: PayloadAction<NydUserQuestion>) => {
            state.allQuestions  = state.allQuestions.concat(action.payload)
         },
    },
})

export const {
   setAllquestions,
   updateAllQuestion,
   updateQuestion
} = appSlice.actions


export default appSlice.reducer