import { Box, Rating, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../../../i18n/i18n.constants';

interface RatingsBoxProps {
    performance: number | null
    service: number | null
    usability: number | null
    setPerfomance: (value: number | null) => void
    setService: (value: number | null) => void
    setUsability: (value: number | null) => void


}

const RatingsBox = ({ performance, service, usability, setPerfomance, setService, setUsability }: RatingsBoxProps): JSX.Element => {
    const { t } = useTranslation(namespaces.components)

    return (
        <Box>
            <Box>
                <Typography component="legend">{t('reviewBox.perfomance.label')}</Typography>
                <Rating
                    name="simple-controlled"
                    precision={0.5}
                    value={performance}
                    onChange={(event, newValue) => {
                        setPerfomance(newValue);
                    }}
                />
            </Box>
            <Box>
                <Typography component="legend">{t('reviewBox.service.label')}</Typography>
                <Rating
                    name="simple-controlled"
                    precision={0.5}
                    value={service}
                    onChange={(event, newValue) => {
                        setService(newValue);
                    }}
                />
            </Box>
            <Box>
                <Typography component="legend">{t('reviewBox.usability.label')}</Typography>
                <Rating
                    name="simple-controlled"
                    precision={0.5}
                    value={usability}
                    onChange={(event, newValue) => {
                        setUsability(newValue);
                    }}
                />
            </Box>
        </Box>
    )
}

export default RatingsBox