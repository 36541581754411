import { namespaces } from "./i18n.constants";
import { booking_en, booking_es, app_en, app_es, dashboard_en, dashboard_es, account_en, account_es, login_en, login_es, register_es, register_en, askYourDoctor_es, askYourDoctor_en, components_es, components_en, adminDashboard_es, adminUserList_es, adminUserQuestions_es, adminUserReviews_es, adminDashboard_en, adminUserList_en, adminUserQuestions_en, adminUserReviews_en, creditStore_es, creditStore_en, home_es, home_en, passwordRecoveryPage_es, passwordRecoveryPage_en, adminUserProfile_es, adminUserProfile_en, about_es, about_en, learnMore_es, learnMore_en, reviews_es, reviews_en, verifyEmail_es, verifyEmail_en, passwordReset_es, passwordReset_en, confirmation_es, confirmation_en, adminCalendarRules_es, adminCalendarRules_en } from "./translations";

export const es = {
    [namespaces.app]: app_es,
    [namespaces.components]: components_es,

    //Static
    [namespaces.pages.login]: login_es,
    [namespaces.pages.register]: register_es,
    [namespaces.pages.home]: home_es,
    [namespaces.pages.about]: about_es,
    [namespaces.pages.learnMore]: learnMore_es,
    [namespaces.pages.reviews]: reviews_es,
    [namespaces.pages.passwordRecovery]: passwordRecoveryPage_es,
    [namespaces.pages.verifyEmail]: verifyEmail_es,
    [namespaces.pages.passwordReset]: passwordReset_es,
    [namespaces.pages.confirmation]: confirmation_es,

    //User
    [namespaces.pages.dashboard]: dashboard_es,
    [namespaces.pages.booking]: booking_es,
    [namespaces.pages.account]: account_es,
    [namespaces.pages.login]: login_es,
    [namespaces.pages.register]: register_es,
    [namespaces.pages.askYourDoctor]: askYourDoctor_es,
    [namespaces.pages.creditStore]: creditStore_es,

    //Admin
    [namespaces.pages.adminDashboard]: adminDashboard_es,
    [namespaces.pages.adminUserList]: adminUserList_es,
    [namespaces.pages.adminUserQuestions]: adminUserQuestions_es,
    [namespaces.pages.adminUserReviews]: adminUserReviews_es,
    [namespaces.pages.adminUserProfile]: adminUserProfile_es,
    [namespaces.pages.adminCalendarRules]: adminCalendarRules_es

};

export const en = {
    [namespaces.app]: app_en,
    [namespaces.components]: components_en,

    //Static
    [namespaces.pages.login]: login_en,
    [namespaces.pages.register]: register_en,
    [namespaces.pages.home]: home_en,
    [namespaces.pages.about]: about_en,
    [namespaces.pages.learnMore]: learnMore_en,
    [namespaces.pages.reviews]: reviews_en,
    [namespaces.pages.verifyEmail]: verifyEmail_en,
    [namespaces.pages.passwordRecovery]: passwordRecoveryPage_en,
    [namespaces.pages.passwordReset]: passwordReset_en,
    [namespaces.pages.confirmation]: confirmation_en,


    //User
    [namespaces.pages.dashboard]: dashboard_en,
    [namespaces.pages.booking]: booking_en,
    [namespaces.pages.account]: account_en,
    [namespaces.pages.login]: login_en,
    [namespaces.pages.register]: register_en,
    [namespaces.pages.askYourDoctor]: askYourDoctor_en,
    [namespaces.pages.creditStore]: creditStore_en,

    //Admin
    [namespaces.pages.adminDashboard]: adminDashboard_en,
    [namespaces.pages.adminUserList]: adminUserList_en,
    [namespaces.pages.adminUserQuestions]: adminUserQuestions_en,
    [namespaces.pages.adminUserReviews]: adminUserReviews_en,
    [namespaces.pages.adminUserProfile]: adminUserProfile_en,
    [namespaces.pages.adminCalendarRules]: adminCalendarRules_en

};
