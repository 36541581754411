import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../redux/hooks'
import { ReviewForm } from './components'
import ReviewsIcon from '@mui/icons-material/Reviews';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../i18n/i18n.constants';

/**
 * UI to Review the app. Will be located inside dashboard and inside review page.
 * @returns 
 */
const ReviewBox = (): JSX.Element => {

    const { t } = useTranslation(namespaces.components)
    const nydProfile = useAppSelector(state => state.user.nydProfile)
    
    const HEIGHT = 200

    /**
     * We use this to handle a safe compoennt
     * @returns 
     */
    const componentToRender = (): JSX.Element => {
        if (nydProfile == null) {
            return <Skeleton variant="rounded" width="100%" height={HEIGHT} />
        }

        const { hasReviewed } = nydProfile

        if (hasReviewed == null) {
            return <Box sx={{ display: 'flex', width: '100%', height: HEIGHT, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <ReviewsIcon fontSize="large" color="disabled" sx={{ mb: 2 }} />
                <Typography variant="body2" color="text.disabled"> {t('reviewBox.undefinedReview')} </Typography>
            </Box>
        }

        if (hasReviewed) {
            return <ReviewForm hasReviewed={true} />
        }

        return <ReviewForm />
    }

    return (
        <Box sx={{ width: '100%', minHeight: HEIGHT, }}>
            {componentToRender()}
        </Box>
    )
}

export default ReviewBox