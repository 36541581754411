import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";
import { TextField } from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../i18n/i18n.constants';

interface BirthDatePickerProps {
    onBirthDateChange: (birthDate: string) => void
    variant?: 'outlined' | 'filled'
    readOnly?: boolean
    initialValue?: string
}

/**
 * Function that provide the input for a BirthDatePicker.
 * It will return a birthDate string as a DD-MM-YYYY
 * @param onBirthDateChange birthDate : string 
 * @returns It will return a birthDate string as a DD-MM-YYYY
 */
const BirthDatePicker = ({ onBirthDateChange, variant = 'outlined', readOnly = false, initialValue }: BirthDatePickerProps): JSX.Element => {

    const { t } = useTranslation(namespaces.components)

    const [birthDate, setBirthDate] = useState<Dayjs | null>(null);
    const [error, setError] = React.useState<DateValidationError | null>(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {
            //   case 'maxDate':
            //   case 'minDate': {
            //     return 'Please select a date in the first quarter of 2022';
            //   }

            case 'invalidDate': {
                return t('birthDate.errorHandling.invalidDate');
            }

            default: {
                return '';
            }
        }
    }, [error]);

    useEffect(() => {
        if (birthDate == null) {
            //is there a initial value?
            if (initialValue) {
                setBirthDate(dayjs(initialValue))
            }
            return
        }

        const year = birthDate.year()
        const month = (birthDate.month() + 1).toString().padStart(2, '0')
        const day = birthDate.date().toString().padStart(2, '0')

        const stringDate = `${year}-${month}-${day}`

        //CALL BACK
        onBirthDateChange(stringDate)
    }, [birthDate])
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DatePicker
                readOnly={readOnly}
                onError={(newError) => setError(newError)}
                inputFormat="YYYY-MM-DD"
                label={t('birthDate.label')}
                value={birthDate}
                onChange={(newValue) => {
                    setBirthDate(newValue);
                }}
                renderInput={(params) => <TextField
                    variant={variant}
                    helperText={errorMessage}
                    required
                    fullWidth {...params} />}
            />
        </LocalizationProvider>
    )
}

export default BirthDatePicker