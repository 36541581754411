import { Theme, useMediaQuery, Box } from '@mui/material'
import { Container } from '@mui/system'
import React, { Suspense, useEffect, useState } from 'react'
import { Routes } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Footer } from '..'
import { generatePages } from '../../../pages/routes'
import { useAppDispatch } from '../../../redux/hooks'
import { setLoadingUser } from '../../../redux/slices/appSlice'
import { setNydAdminProfile } from '../../../redux/slices/nydAdminSlice'
import { setNextAppointments, setNydProfile, setNydUserReview, setSaludtoolsProfile } from '../../../redux/slices/userSlice'
import { isApiError } from '../../../services/ApiError'
import { userController } from '../../../services/UserController'
import ScrollToTop from './components/ScrollToTop'

//To do!
const Loading = (): JSX.Element => <p>Loading ...</p>;

/**
 * Here wwe will storage all the routes
 * @returns 
 */
const Main = (): JSX.Element => {

    const detectRoutePath = (pathname: string): boolean => {
        return pathname.includes("/user/") || pathname.includes("/admin/");
    };

    const location = useLocation();
    const [isInUserOrAdminRoute, setIsInUserOrAdminRoute] = useState(detectRoutePath(location.pathname));

    useEffect(() => {
        setIsInUserOrAdminRoute(detectRoutePath(location.pathname));
    }, [location.pathname]);

    const dispatch = useAppDispatch()

    const getCurrentUser = async (): Promise<void> => {

        const request = await userController.getUserFromTokens()

        if (isApiError(request)) {
            dispatch(setLoadingUser(false))
            return
        }

        const { user, nydReview, saludtools, nextAppointments } = request

        if ("role" in user) {
            dispatch(setNydAdminProfile(user))
        } else {
            if (nydReview) {
                dispatch(setNydUserReview(nydReview))
            }
            dispatch(setNydProfile(user))

        }

        if (typeof saludtools !== 'string') {
            dispatch(setSaludtoolsProfile(saludtools))
        }

        if (typeof nextAppointments !== 'string') {
            dispatch(setNextAppointments(nextAppointments))
        }

        //******* */
        dispatch(setLoadingUser(false))
        return
    }

    useEffect(() => {
        getCurrentUser()
    }, [])

    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    return (
        <Box
            component="main"
            sx={{
                minHeight: '90vh',
                display: 'flex',
                flexDirection: 'column'
            }}>
            <Container
                maxWidth="xl"
                sx={{ py: 15 }}>
                <Suspense fallback={<Loading />}>
                    <ScrollToTop />
                    <Routes>
                        {generatePages()}
                    </Routes>
                </Suspense>
            </Container>
            
            {
                isInUserOrAdminRoute && !isMd && <Footer />
            }
        </Box>
    )
}

export default Main