import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

/**
 * This File will contain all Routes for the project.
 * Also Functions for generating new dynamic routes.
 */

import { lazy } from 'react';
import { Route } from 'react-router';
import WebAppLayout from '../app/components/WebAppLayout/WebAppLayout';
import { useAppSelector } from '../redux/hooks';
import NydAppPages from '../types/App/NydAppPages';


//**** STEP 1 Lazy imports ****/

const AboutPage = lazy(() => import('./static/About/AboutPage'))
const LandingPage = lazy(() => import('./static/Landing/LandingPage'))
const PricesPage = lazy(() => import('./static/Prices/PricesPage'))
const ReviewsPage = lazy(() => import('./static/Reviews/ReviewsPage'))
const LoginPage = lazy(() => import('./static/Login/LoginPage'))
const RegisterPage = lazy(() => import('./static/Register/RegisterPage'))
const HomePage = lazy(() => import('./static/Home/HomePage'))
const PasswordRecoveryPage = lazy(() => import('./static/PasswordRecovery/PasswordRecoveryPage'));
const LearnMorePage = lazy(() => import('./static/LearnMore/LearnMorePage'))
const VerifyEmailPage = lazy(() => import('./static/VerifyEmail/VerifyEmailPage'))
const PasswordResetPage = lazy(() => import('./static/PasswordReset/PasswordResetPage'));
const ConfirmationPage = lazy(() => import('./static/Confirmation/ConfirmationPage'))


//User
const DashboardPage = lazy(() => import('./user/Dashboard/DashboardPage'));
const BookingPage = lazy(() => import('./user/Booking/BookingPage'));
const AccountPage = lazy(() => import('./user/Account/AccountPage'));
const AskYourDoctorPage = lazy(() => import('./user/AskYourDoctor/AskYourDoctorPage'));
const CreditStorePage = lazy(() => import('./user/CreditStore/CreditStorePage'));

//Admin
const AdminDashboardPage = lazy(() => import('./admin/AdminDashboard/AdminDashboardPage'));
const AdminUserListPage = lazy(() => import('./admin/AdminUserList/AdminUserListPage'));
const AdminUserReviewsPage = lazy(() => import('./admin/AdminUserReviews/AdminUserReviewsPage'));
const AdminUserQuestionsPage = lazy(() => import('./admin/AdminUserQuestions/AdminUserQuestionsPage'));
const AdminUserProfilePage = lazy(() => import('./admin/AdminUserProfile/AdminUserProfilePage'))
const AdminReviewPage = lazy(() => import('./admin/AdminReview/AdminReviewPage'))
const AdminQuestionPage = lazy(() => import('./admin/AdminQuestion/AdminQuestionPage'))
const AdminCalendarRulesPage = lazy(() => import('./admin/AdminCalendarRules/AdminCalendarRulesPage'))

//Other
const TestPage = lazy(() => import('./Test/TestPage'));
const NotFoundPage = lazy(() => import('./static/NotFound/NotFoundPage'));
const AboutDevelopmentPage = lazy(() => import('./static/AboutDevelopment/AboutDevelopmentPage'))
const DocumentsPage = lazy(() => import('./static/Documents/DocumentsPage'))

//**** STEP 2 Enum the Route ****/

/**
 * Unique paths of each routes. Useful for translations.
 */
export enum RoutePaths {
  //STATIC Paths
  Home = '/',
  Landing = '/nefrologo-en-linea', //This route should be in SPANISH.
  About = '/about',
  Prices = '/prices',
  Reviews = '/reviews',
  Login = '/login',
  Register = '/register',
  PasswordRecovery = '/password-recovery',
  LearnMore = '/learn-more',
  VerifyEmail = '/verify-email',
  PasswordReset = '/password-reset',
  Confirmation = '/confirmation',

  //USER Paths
  Dashboard = '/user/dashboard',
  Account = '/user/account',
  Booking = '/user/booking',
  AskYourDoctor = '/user/ask-your-doctor',
  CreditStore = '/user/credit-store',

  //ADMIN Paths
  AdminDashboard = '/admin/dashboard',
  AdminUserList = '/admin/user-list',
  AdminUserProfile = '/admin/user-list/:userId',
  AdminUserQuestions = '/admin/user-questions',
  AdminQuestion = '/admin/user-questions/:questionId',
  AdminUserReviews = '/admin/user-reviews',
  AdminReview = '/admin/user-reviews/:reviewId',
  AdminCalendarRules = '/admin/calendar-rules',

  //OTHER Paths
  Test = '/test',
  AboutDevelopment = '/about/development',
  Documents = '/documents'
}

//**** STEP 3 Translate the page ****/

const routePathTranslations = {
  [RoutePaths.Home]: 'routeNames.home',
  [RoutePaths.Dashboard]: 'routeNames.dashboard',
  [RoutePaths.Booking]: 'routeNames.booking',
  [RoutePaths.Test]: 'routeNames.test',
  [RoutePaths.Account]: 'routeNames.account',
  [RoutePaths.Login]: 'routeNames.login',
  [RoutePaths.Register]: 'routeNames.register',
  [RoutePaths.Landing]: 'routeNames.landing',
  [RoutePaths.About]: 'routeNames.about',
  [RoutePaths.Prices]: 'routeNames.prices',
  [RoutePaths.Reviews]: 'routeNames.reviews',
  [RoutePaths.AskYourDoctor]: 'routeNames.askYourDoctor',
  [RoutePaths.AdminDashboard]: 'routeNames.adminDashboard',
  [RoutePaths.AdminUserList]: 'routeNames.adminUserList',
  [RoutePaths.AdminUserProfile]: 'routeNames.adminUserProfile',
  [RoutePaths.AdminUserQuestions]: 'routeNames.adminUserQuestions',
  [RoutePaths.AdminQuestion]: 'routeNames.adminQuestion',
  [RoutePaths.AdminUserReviews]: 'routeNames.adminUserReviews',
  [RoutePaths.AdminReview]: 'routeNames.adminReview',
  [RoutePaths.AdminCalendarRules]: 'routeNames.adminCalendarRules',
  [RoutePaths.CreditStore]: 'routeNames.creditStore',
  [RoutePaths.PasswordRecovery]: 'routeNames.passwordRecovery',
  [RoutePaths.LearnMore]: 'routeNames.learnMore',
  [RoutePaths.VerifyEmail]: 'routeNames.verifyEmail',
  [RoutePaths.PasswordReset]: 'routeNames.passwordReset',
  [RoutePaths.Confirmation]: 'routeNames.confirmation',
  [RoutePaths.AboutDevelopment]: 'routeNames.aboutDevelopment',
  [RoutePaths.Documents]: 'routeNames.documents',
};
/**
 * Function that translate route. To add a translation please find inside i18n README how to do it.
 * @param str
 * @returns
 */
export const translateRoutePaths = (str: RoutePaths): string => {
  return routePathTranslations[str] || '';
};

//**** STEP 4 Build menu Pages across the site ****/

/**
 * Assign an Icon for each page.
 * Assign hidden if the page should not sappear in the navigation
 */
const pages: NydAppPages[] = [
  //Static Pages
  {
    page: RoutePaths.Home,
    element: <HomePage />
  },
  {
    page: RoutePaths.About,
    element: <AboutPage />
  },
  {
    page: RoutePaths.Landing,
    element: <LandingPage />
  },
  {
    page: RoutePaths.Prices,
    element: <PricesPage />
  },
  {
    page: RoutePaths.Reviews,
    element: <ReviewsPage />
  },
  {
    page: RoutePaths.Login,
    element: <LoginPage />
  },
  {
    page: RoutePaths.Register,
    element: <RegisterPage />
  },
  {
    page: RoutePaths.PasswordRecovery,
    element: <PasswordRecoveryPage />
  },
  {
    page: RoutePaths.LearnMore,
    element: <LearnMorePage />
  },
  {
    page: RoutePaths.VerifyEmail,
    element: <VerifyEmailPage />
  },
  {
    page: RoutePaths.PasswordReset,
    element: <PasswordResetPage />
  },
  {
    page: RoutePaths.Confirmation,
    element: <ConfirmationPage />
  },
  {
    page: RoutePaths.AboutDevelopment,
    element: <AboutDevelopmentPage />
  },
  {
    page: RoutePaths.Documents,
    element: <DocumentsPage />
  },

  // Admin Pages
  {
    page: RoutePaths.AdminDashboard,
    icon: <DashboardIcon />,
    element: <AdminDashboardPage />
  },
  {
    page: RoutePaths.AdminUserList,
    icon: <PersonSearchIcon />,
    element: <AdminUserListPage />
  },
  {
    page: RoutePaths.AdminUserQuestions,
    icon: <QuestionAnswerIcon />,
    element: <AdminUserQuestionsPage />
  },
  {
    page: RoutePaths.AdminUserReviews,
    icon: <ReviewsIcon />,
    element: <AdminUserReviewsPage />
  },
  {
    page: RoutePaths.AdminUserProfile,
    element: <AdminUserProfilePage />,
    hidden: true
  },
  {
    page: RoutePaths.AdminQuestion,
    element: <AdminQuestionPage />,
    hidden: true
  },
  {
    page: RoutePaths.AdminReview,
    element: <AdminReviewPage />,
    hidden: true
  },
  {
    page: RoutePaths.AdminCalendarRules,
    icon: <CalendarMonthIcon />,
    element: <AdminCalendarRulesPage />,
  },

  //User Pages
  {
    page: RoutePaths.CreditStore,
    icon: <AddShoppingCartIcon />,
    element: <CreditStorePage />
  },
  {
    page: RoutePaths.Dashboard,
    icon: <DashboardIcon />,
    element: <DashboardPage />
  },
  {
    page: RoutePaths.Booking,
    icon: <CalendarMonthIcon />,
    element: <BookingPage />
  },
  {
    page: RoutePaths.AskYourDoctor,
    icon: <QuestionAnswerIcon />,
    element: <AskYourDoctorPage />
  },
  {
    page: RoutePaths.Account,
    icon: <AccountBoxIcon />,
    element: <AccountPage />
  },
]


//**** STEPS FINISHED -  BELOW IS AUTOMATED */
//**** AUTOMATIC Check if the page belongs to Admin or User App  or static****/

const adminPages = (): JSX.Element | null => {
  return (
    <Route element={<WebAppLayout />}>
      {pages.map((item) => {
        const { page, element } = item;
        if (!page.includes('/admin/')) return;
        return <Route key={page} path={page} element={element} />;
      })}
    </Route>
  );
};

const userPages = (): JSX.Element | null => {
  return (
    <Route element={<WebAppLayout />}>
      {pages.map((item) => {
        const { page, element, hidden } = item;
        if (hidden) return
        if (!page.includes('/user/')) return;
        return <Route key={page} path={page} element={element} />;
      })}
    </Route>
  );
};

const staticPages = (): JSX.Element | null => {
  return (
    <>
      {pages.map((item) => {
        const { page, element } = item;
        if (page.includes('/user/') || page.includes('/admin/')) return;
        return <Route key={page} path={page} element={element} />;
      })}
    </>
  );
};

/**
 * Function to generate pages.  This functionis imported inside main.tsx inside App folder.
 * @returns
 */
export const generatePages = (): JSX.Element => {
  return (
    <>
      {staticPages()}
      {userPages()}
      {adminPages()}
      <Route path={RoutePaths.Test} element={<TestPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </>
  )
}

/**
 * Function to get Top account menu
 * @param user 
 * @returns 
 */
export const menuForAccount = (user: 'user' | 'admin'): RoutePaths[] => {
  const menuPages = pages.map(item => {
    const { page, hidden } = item
    if (hidden) return
    if (!page.includes(`/${user}/`)) return
    return page
  })
  return menuPages.filter(item => item !== undefined) as RoutePaths[]
}

/**
 * function that generate the menu on the side for Admin
 * @returns 
 */
export const menuForAdmin = (): { page: RoutePaths, icon: JSX.Element }[] | null => {

  const isThereAdmin = useAppSelector(state => state.nydAdmin.nydAdmin)
  if (!isThereAdmin) return null
  const menuPages = pages.map(item => {
    const { page, icon, hidden } = item
    if (hidden) return
    if (!page.includes("/admin/")) return
    return { page, icon }
  })

  return menuPages.filter(item => item !== undefined) as { page: RoutePaths, icon: JSX.Element }[];
};

/**
 * function that generate the menu on the side for User
 * @returns 
 */
export const menuForUser = ():
  | { page: RoutePaths, icon?: JSX.Element }[]
  | null => {
  const isThereUser = useAppSelector((state) => state.user.nydProfile);
  if (!isThereUser) return null;
  const menuPages = pages.map((item) => {
    const { page, icon, hidden } = item;
    if (hidden) return
    if (!page.includes('/user/')) return;
    return { page, icon };
  });

  return menuPages.filter((item) => item !== undefined) as { page: RoutePaths, icon?: JSX.Element }[];
};

/**
 * Function to return the dynamic page path of a user profile
 * @param userId 
 * @returns 
 */
export const getDynamicUserProfilePath = (userId: string): string => {
  const path = RoutePaths.AdminUserProfile.replace(':userId', userId)
  return path
}

/**
 * Function to return the dynamic page path of a review
 * @param reviewId 
 * @returns 
 */
export const getDynamicReviewPath = (reviewId: string): string => {
  const path = RoutePaths.AdminReview.replace(':reviewId', reviewId)
  return path
}

/**
 * Function to return the dynamic page path of a question
 * @param questionId 
 * @returns 
 */
export const getDynamicQuestionPath = (questionId: string): string => {
  const path = RoutePaths.AdminQuestion.replace(':questionId', questionId)
  return path
}