import { Avatar, Box, Chip, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { namespaces } from '../../../../../i18n/i18n.constants';
import { menuForAccount, RoutePaths, translateRoutePaths } from '../../../../../pages/routes';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { LoginArea } from './components';
import LogoutIcon from '@mui/icons-material/Logout';
import { sessionController } from '../../../../../services/SessionController';
import { isApiError } from '../../../../../services/ApiError';
import { resetUserSliceValues } from '../../../../../redux/slices/userSlice';
import { resetNydAdminSliceValues } from '../../../../../redux/slices/nydAdminSlice';


/**
 * Everything related to the account
 * @returns 
 */
const AccountMenu = (): JSX.Element => {

    const { t } = useTranslation(namespaces.app)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (): void => {
        setAnchorElUser(null);
    };
    const nydUser = useAppSelector(state => state.user.nydProfile)
    const nydAdmin = useAppSelector(state => state.nydAdmin.nydAdmin)

    const [pages, setPages] = useState<RoutePaths[] | undefined>(undefined)

    const endSession = async (): Promise<void> => {

        const response = await sessionController.deleteSession()

        if (isApiError(response)) {
            // TO DO - Model something went wrong? 
            alert(`Something went wrong, ${response.error}`)
        }
        //Close menu
        handleCloseUserMenu()

        //Remove local
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // TO DO - We need render dynamic if the user is Admin or not?
        //Remove redux 
        dispatch(resetUserSliceValues())
        dispatch(resetNydAdminSliceValues())

        // Navigate to login
        navigate(RoutePaths.Login)
    }

    useEffect(() => {
        if (nydUser == null && nydAdmin == null) return
        let pagesToShow: RoutePaths[] | undefined = undefined
        if (nydUser) {
            pagesToShow = menuForAccount('user')
        } else if (nydAdmin) {
            pagesToShow = menuForAccount('admin')
        }

        setPages(pagesToShow)

    }, [nydUser, nydAdmin])

    return (
        <Box sx={{ flexGrow: 0 }}>
            {
                !nydUser && !nydAdmin
                    ? <LoginArea />
                    : <>
                        <Tooltip
                            title="Open account">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Chip
                                    sx={{ cursor: 'pointer' }}
                                    avatar={<Avatar>{nydUser?.firstName.slice(0, 1) ?? nydAdmin?.firstName.slice(0, 1)}</Avatar>}
                                    label={nydUser?.lastName ?? nydAdmin?.lastName}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {pages?.map((page) => (
                                <MenuItem
                                    component={Link}
                                    to={page}
                                    key={page}
                                    onClick={handleCloseUserMenu}>

                                    <Typography textAlign="center">{t(translateRoutePaths(page))}</Typography>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem onClick={endSession}>
                                <LogoutIcon sx={{ mr: 0.5 }} />
                                <Typography textAlign="center">{t('accountMenu.logOut')}</Typography>
                            </MenuItem>
                        </Menu>
                    </>
            }
        </Box>
    )
}

export default AccountMenu