import { Alert, Box, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { lightTheme } from '../theme';
import './App.css';
import { Footer, Header, Main } from './components';
import { store } from '../redux/store';
import { useLocation } from 'react-router-dom';

const App = (): JSX.Element => {

  const detectRoutePath = (pathname: string): boolean => {
    return pathname.includes("/user/") || pathname.includes("/admin/");
  };

  const location = useLocation();
  const [isInUserOrAdminRoute, setIsInUserOrAdminRoute] = useState(detectRoutePath(location.pathname));

  useEffect(() => {
    setIsInUserOrAdminRoute(detectRoutePath(location.pathname));
  }, [location.pathname]);

  const isDevelopment = window.location.hostname !== 'nefrologiaenlinea.com' && window.location.hostname !== 'www.nefrologiaenlinea.com'
  return (
    <Provider store={store}>
      <ThemeProvider theme={responsiveFontSizes(lightTheme)}>
        <CssBaseline />

        {
          isDevelopment && <Box sx={{ position: 'fixed', bottom: 10, right: 0, zIndex: 100 }}>
            <Alert sx={{ mt: 10 }} variant="filled" severity="warning">
              YOU ARE IN DEVELOPMENT MODE
            </Alert>
          </Box>
        }

        {
          !isInUserOrAdminRoute && <Header />
        }
        <Main />
        {
          !isInUserOrAdminRoute && <Footer />
        }
      </ThemeProvider>
    </Provider>
  );
}

export default App;