import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { NefrologiaYDialisisSasInfo } from '../../../../../constants'
import NefrologiaYDialisisLogo from '../../../../../assets/images/logoNyd.png'

const LogoLoader = (): JSX.Element => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0
        }}>

            <Box
                sx={{
                    '& > img': {
                        objectFit: 'contain',
                    }
                }}>
                <img src={NefrologiaYDialisisLogo} alt={`${NefrologiaYDialisisSasInfo.legalName} Logo.`} />
            </Box>
            <CircularProgress color="secondary" />
        </Box>
    )
}

export default LogoLoader