import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { NydAdmin, NydPagination } from '../../types/NefrologiaYDialisis'
import { UpdatePagination } from '../../types/App'
import { PaginationType } from '../../enums/AdminApp'



// Define a type for the slice state
interface UserState {

    /**
     * Current Admin connected.
     */
    nydAdmin?: NydAdmin

    /**
     * Size of the tables.
     */
    sizePerPage: number

    /**
     * Pagination for user list.
     */
    userListPagination?: NydPagination

    /**
     * Pagination for reviews
     */
    reviewsPagination?: NydPagination

    /**
     * Questions Pagination.
     */
    questionsPagination?: NydPagination
}

// Define the initial state using that type
const initialState: UserState = {
    // To mock data please use dummyNydAmin
    nydAdmin: undefined,
    sizePerPage: 10,
}

export const nydAdminSlice = createSlice({
    name: 'nydAdmin',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetNydAdminSliceValues: (state) => {
            state.nydAdmin = undefined,
            state.sizePerPage = 10,
            state.questionsPagination = undefined
            state.reviewsPagination = undefined
            state.questionsPagination = undefined
        },
        updatePagination: (state, action: PayloadAction<UpdatePagination>) => {
            const { pagination, type } = action.payload
            switch (type) {
                case PaginationType.Questions:
                    state.questionsPagination = pagination
                    break;
                case PaginationType.Reviews:
                    state.reviewsPagination = pagination
                    break;
                case PaginationType.UserList:
                    state.userListPagination = pagination
            }
        },
        updateSizePerPage: (state, action: PayloadAction<number>) => {
            state.sizePerPage = action.payload  
        },
        setNydAdminProfile: (state, action: PayloadAction<NydAdmin | undefined>) => {
            state.nydAdmin = action.payload
        }
    },
})

export const {
    resetNydAdminSliceValues,
    updatePagination,
    updateSizePerPage,
    setNydAdminProfile
} = nydAdminSlice.actions


export default nydAdminSlice.reducer