
import { createTheme } from '@mui/material/styles';


// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#224d6d',
    },
    secondary: {
      main: '#92c137',
    },
    error: {
      main: '#c91d1d',
    },
    warning: {
      main: '#FFC107',
    },
    info: {
      main: '#03A9F4',
    },
    success: {
      main: '#66BB6A',
    },
    background: {
      default: '#FAFAFA',
      paper: '#fff',
    },
    divider: 'rgba(0,0,0,0.12)',
  },
});

export default theme;