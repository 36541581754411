import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import MenuIcon from '@mui/icons-material/Menu';
import { menuForAdmin, menuForUser, RoutePaths, translateRoutePaths } from '../../../pages/routes';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../i18n/i18n.constants';
import { Link } from 'react-router-dom';
import { ToggleLanguage } from '../../../components';
import { Logo } from '../Header/components';
import { Footer } from '..';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import { sessionController } from '../../../services/SessionController';
import { isApiError } from '../../../services/ApiError';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { resetUserSliceValues } from '../../../redux/slices/userSlice';
import { resetNydAdminSliceValues } from '../../../redux/slices/nydAdminSlice';
import { LogoLoader } from './components';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

/**
 * Layout exclusive for ADMIN and USER pages. 
 * @returns 
 */
const WebAppLayout = (): JSX.Element => {
    const { t } = useTranslation(namespaces.app)
    const location = useLocation();
    const currentPath = location.pathname as RoutePaths;
    
    const [mobileOpen, setMobileOpen] = useState(false);

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const handleDrawerToggle = (): void => {
        setMobileOpen(!mobileOpen);
    };

    const endSession = async (): Promise<void> => {

        const response = await sessionController.deleteSession()

        if (isApiError(response)) {
            // TO DO - Model something went wrong? 
            alert(`Something went wrong, ${response.error}`)
        }

        //Remove local
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // TO DO - We need render dynamic if the user is Admin or not?
        //Remove redux 
        dispatch(resetUserSliceValues())
        dispatch(resetNydAdminSliceValues())

        // Navigate to login
        navigate(RoutePaths.Login)
    }


    const nydUser = useAppSelector(state => state.user.nydProfile)
    const nydAdmin = useAppSelector(state => state.nydAdmin.nydAdmin)
    const loadingUser = useAppSelector(state => state.app.loadingUser)

    useEffect(() => {
        if (loadingUser) return
        if (location.pathname.includes('/user/')) {
            if (nydUser) return
            navigate(RoutePaths.Login)
            return

        } else if (location.pathname.includes('/admin/')) {
            if (nydAdmin) return
            navigate(RoutePaths.Login)
            return
        }

    }, [loadingUser])

    // TO DO - REFACTOR
    const pagesAdmin = menuForAdmin()
    const pagesUser = menuForUser()

    const drawer = (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Logo />
            <Divider />
            <List>
                {
                    pagesAdmin?.map(item => {
                        const translatedText = t(translateRoutePaths(item.page))

                        return (
                            <ListItem component={Link} to={item.page} key={item.page} disablePadding>
                                <ListItemButton selected={currentPath === item.page}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ color: 'text.primary' }}
                                        primary={translatedText} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
                {
                    pagesUser?.map(item => {
                        const translatedText = t(translateRoutePaths(item.page))

                        return (
                            <ListItem component={Link} to={item.page} key={item.page} disablePadding>
                                <ListItemButton selected={currentPath === item.page}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ color: 'text.primary' }}
                                        primary={translatedText} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
            </List>

            <Divider />
            <List>
                <ListItem>
                    <ListItemIcon>
                        <TranslateIcon />
                    </ListItemIcon>
                    <ToggleLanguage />
                </ListItem>
            </List>
            <Divider />
            <List sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'flex-end'
            }}>
                <ListItem
                    sx={{ alignSelf: 'flex-end' }}
                    onClick={endSession}>
                    <ListItemButton sx={(theme: Theme) => ({
                        border: '1px solid',
                        borderColor: theme.palette.error.light,
                        borderRadius: "5px",

                    })}>
                        <ListItemIcon>
                            <LogoutIcon color="error" />
                        </ListItemIcon>
                        <Typography
                            color="error.light"
                        >
                            {t('accountMenu.logOut')}
                        </Typography>
                    </ListItemButton>
                </ListItem>
            </List>
            <Box sx={{ alignItems: 'flex-end', display: 'flex' }}>
                {
                    isSm && <Footer />
                }
            </Box>
        </Box>
    );


    return (
        <Box>
            {
                nydAdmin == null && nydUser == null
                    ? <LogoLoader />
                    : <Box sx={{ display: 'flex'}}>
                        <CssBaseline />
                        <AppBar
                            color="default"
                            position="fixed"
                            sx={{
                                display: { sm: 'none' },
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                            }}
                        >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" noWrap component="div">
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Box
                            component="nav"
                            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                            aria-label="mailbox folders"
                        >
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Drawer
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                            >
                                {drawer}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    background: 'yellow',
                                    display: { xs: 'none', sm: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: 0 },
                                }}
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                        <Outlet />
                    </Box>
            }


        </Box>
    )
}

export default WebAppLayout