import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReviewsIcon from '@mui/icons-material/Reviews';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../i18n/i18n.constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import CircleScore from '../../../CircleScore/CircleScore';
import { RatingsBox } from './components';
import { projectRules } from '../../../../assets/data/projectRules';
import { NotificationMessage } from '../../../../types/App';
import { reviewController } from '../../../../services/ReviewController';
import StarsIcon from '@mui/icons-material/Stars';
import { isApiError } from '../../../../services/ApiError';
import { CreateUserReviewResponse, UpdateUserReviewResponse } from '../../../../types/services/UserReviewController/response';
import { ApiError } from '../../../../types/Api';
import { setNydProfile, setNydUserReview } from '../../../../redux/slices/userSlice';



interface ReviewFormProps {
  hasReviewed?: boolean
}

/**
 * UI to Review the app. Will be located inside dashboard and inside review page.
 * @returns 
 */
const ReviewForm = ({ hasReviewed = false }: ReviewFormProps): JSX.Element => {

  const { t } = useTranslation(namespaces.components)
  const dispatch = useAppDispatch()

  const [open, setOpen] = useState<boolean>(false)
  const review = useAppSelector(state => state.user.nydUserReview)

  const [allowSubmit, setAllowSubmit] = useState<boolean>(false)

  const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined)

  const [performance, setPerfomance] = useState<number | null>(review?.scorePerformance ?? null)
  const [service, setService] = useState<number | null>(review?.scoreService ?? null)
  const [usability, setUsability] = useState<number | null>(review?.scoreUsability ?? null)
  const [avgScore, setAvgScore] = useState<number | undefined>(review?.average ?? undefined)
  const [textReview, setTextReview] = useState<string>(review?.review ?? '')

  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => {
    setOpen(false)
    setNotification(undefined)
  }
  const handleSubmit = async (event: { preventDefault: () => void; }): Promise<void> => {
    event.preventDefault()
    if (performance == null || service == null || usability == null) {
      return
    }

    let response: CreateUserReviewResponse | UpdateUserReviewResponse | undefined | ApiError

    if (review) {
     
      response = await reviewController.updateUserReview({ _id: review._id, scorePerformance: performance, scoreUsability: usability, scoreService: service, review: textReview })
    } else {
      response = await reviewController.createUserReview({ scorePerformance: performance, scoreUsability: usability, scoreService: service, review: textReview })
    }

    if (isApiError(response)) {
      setNotification({
        message: response.error,
        type: 'error'
      })

      return
    }

    const { nydUserReview, message } = response
    dispatch(setNydUserReview(nydUserReview))
    if ('nydUser' in response) {
      dispatch(setNydProfile(response.nydUser))
    } 
    setNotification({
      message,
      type: 'success'
    })
  }

  /**
   * This useEffect would genarate the average score and check if form is ready to submit
   */
  useEffect(() => {
    if (performance == null || service == null || usability == null) {
      setAllowSubmit(false)
      return
    }

    const avg = (performance + service + usability) / 3
    const rounded = (Math.round(avg * 10) / 10 * 2);

    setAllowSubmit(true)
    setAvgScore(rounded)
  }, [performance, service, usability])

  const placeholder = t('reviewBox.textArea.placeholder')

  const componentToRender = (): JSX.Element => {
    //IF NOT OPEN - THE OPTIONS TO OPEN THE BOX
    if (!open) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <Button variant="text" onClick={handleOpen} color="primary" sx={{ display: 'flex', flexDirection: 'column' }}>
            <ReviewsIcon fontSize="large" color="secondary" sx={{ mb: 2 }} />
            {
              hasReviewed
                ? t('reviewBox.updateRate')
                : t('reviewBox.rateThisApp')
            }
          </Button>
        </Box>
      )
    }

    //IS OPEN and HAVE A SUCCESS MESAGE, means it finished the process.
    if (notification?.type === 'success') {
      return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, flexDirection: 'column' }}>
        <StarsIcon fontSize="large" color="secondary" sx={{ mb: 2 }} />
        <Typography variant="body2">{t('reviewBox.reviewSent')}</Typography>
        <Button variant="text" onClick={handleClose} color="primary" sx={{ display: 'flex', flexDirection: 'column' }}>
          {t('reviewBox.okay')}
        </Button>
      </Box>
    }

    //Other IS OPEN and the user can perform a review
    return <Grid container spacing={2} alignItems="center">
      <Grid item xs={6} md={6}>
        <RatingsBox
          performance={performance}
          service={service}
          usability={usability}
          setPerfomance={setPerfomance}
          setUsability={setUsability}
          setService={setService}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <CircleScore score={avgScore} />
        </Box>

      </Grid>
      <Grid item xs={12}>
        <TextField
          value={textReview}
          onChange={(event) => {
            setTextReview(event.target.value)
          }}
          fullWidth
          label={t("reviewBox.textArea.label")}
          placeholder={placeholder}
          multiline
          rows={10}
          maxRows={10}
          inputProps={{ maxLength: projectRules.reviewBox.textReviewLimit }}
        />
      </Grid>
      <Grid item xs={12}>
        {
          notification && <Alert sx={{ mb: 2 }} severity={notification.type}>{notification.message}</Alert>
        }
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Button onClick={handleClose}>{t("reviewBox.cancel")}</Button>
          <Button
            disabled={!allowSubmit}
            variant="contained" color="secondary" onClick={handleSubmit}>{t("reviewBox.send")}</Button>
        </Box>
      </Grid>
    </Grid>
  }

  return (
    <Box sx={{ minHeight: 200, m: 2, }}>
      {
        componentToRender()
      }
    </Box >
  )
}

export default ReviewForm