import dayjs, { Dayjs } from "dayjs"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { projectRules } from "../assets/data/projectRules";

dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * Collection of utitlies that are been use on the project.
 * Normally they dont belong to any category.
 */
class UtilityServices {

    /**
     * Function that converts an ISO time to a readable Date and time.
     * @param isoStr 
     * @returns 
     */
    readableDateTime = (isoStr: string | undefined): string => {
        if (isoStr == null) {
            return '-'
        }
        const date = new Date(isoStr)

        return `${date.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })}, ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`
    }

    /**
    * Function that converts an ISO time to a readable Date.
    * @param isoStr 
    * @returns 
    */
    readableDate = (isoStr: string | undefined): string => {
        if (isoStr == null) {
            return '-'
        }
        const date = new Date(isoStr)

        return `${date.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })}`
    }

    /**
   * Function to repeat string information inside the grid area
   */
    repeatStr = (str: string, num: number): string => {
        if (num < 0) {
            return ""
        } else if (num === 1) {
            return `${str} `
        } else {
            let repeatedStr = ""
            while (num > 0) {
                repeatedStr += `${str} `
                num--
            }
            return repeatedStr;
        }
    }

    /**
     * Function that converts Date to a readable string. Ex: 'Feb 02'
     * @param date 
     * @returns 
     */
    shortMonthAndDay2Digit = (date?: Date): string => {
        if (date == null) {
            return ''
        }
        return date.toLocaleDateString(undefined, { month: 'short', day: '2-digit' })
    }

    /**
     * It takes a birth date in this format yyyy-mm-dd and return the age.
     * @param birthdate 
     * @returns 
     */
    calculateAge = (birthdate: string): number => {
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * Get average number
     * @param arr 
     * @returns 
     */
    average = (arr: number[]): number => {
        const average = arr.reduce((p, c) => p + c, 0) / arr.length;

        return Number(average.toFixed(1))
    }


    /**
     * Next possible date to a booking. Never weekends.
     */
    nextPossibleDate = (): Dayjs => {
        const date = dayjs().add(projectRules.booking.minDayToBook, 'day')
        if (date.day() === 0) {
            return date.add(1, 'day')
        } else if (date.day() === 6) {
            return date.add(2, 'day')
        } else {
            return date
        }
    }

    //TODO  This logic should be handle by backend. 
    domiciliaryAllow = (address: string | undefined): boolean => {
        if(!address) return false
        const lowCase = address.toLowerCase()
        const cartagena = lowCase.includes('cartagena')
        const colombia = lowCase.includes('colombia')

        return cartagena && colombia
    }

}

export const utilityServices = new UtilityServices()