import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MonthsConfiguration, NydCalendarRule, TimeRange, WeekdaysConfiguration } from '../../types/NefrologiaYDialisis'
import { SaludToolsModality } from '../../enums/SaludTools'
import { DateHandlerAdvanceChanges, DateHandlerSimpleChanges, TimeHandlerChanges } from '../../pages/admin/AdminCalendarRules/components'

// Define a type for the slice state
interface CalendarRuleState {
    /**
     * CalendarRule for Telemedicine
     */
    telemedicineCalendarRule?: NydCalendarRule
    telemedicineCalendarRuleCopy?: NydCalendarRule

    /**
     * CalendarRule for Conventional
     */
    conventionalCalendarRule?: NydCalendarRule
    conventionalCalendarRuleCopy?: NydCalendarRule

    /**
   * CalendarRule for Domicialiary
   */
    domiciliaryCalendarRule?: NydCalendarRule
    domiciliaryCalendarRuleCopy?: NydCalendarRule

    telemedicineChange: boolean;
    conventionalChange: boolean;
    domiciliaryChange: boolean;
}

// Define the initial state using that type
const initialState: CalendarRuleState = {
    telemedicineCalendarRule: undefined,
    conventionalCalendarRule: undefined,
    domiciliaryCalendarRule: undefined,
    telemedicineCalendarRuleCopy: undefined,
    conventionalCalendarRuleCopy: undefined,
    domiciliaryCalendarRuleCopy: undefined,
    telemedicineChange: false,
    domiciliaryChange: false,
    conventionalChange: false

}

export const calendarRuleSlice = createSlice({
    name: 'calendarRule',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCalendarRule: (state, action: PayloadAction<NydCalendarRule>) => {
            const { modality } = action.payload
            switch (modality) {
                case SaludToolsModality.Conventional: {
                    state.conventionalCalendarRule = action.payload
                    state.conventionalCalendarRuleCopy = action.payload
                    break;
                }
                case SaludToolsModality.Telemedicine: {
                    state.telemedicineCalendarRule = action.payload
                    state.telemedicineCalendarRuleCopy = action.payload
                    break;
                }
                case SaludToolsModality.Domiciliary: {
                    state.domiciliaryCalendarRule = action.payload
                    state.domiciliaryCalendarRuleCopy = action.payload
                    break;
                }
            }
        },
        resetCalendarRule: (state) => {
            state.conventionalChange = false;
            state.telemedicineChange = false;
            state.domiciliaryChange = false;
            state.conventionalCalendarRuleCopy = state.conventionalCalendarRule
            state.telemedicineCalendarRuleCopy = state.telemedicineCalendarRule
            state.domiciliaryCalendarRuleCopy = state.domiciliaryCalendarRule
        },
        setGlobalTimeHandler: (state, action: PayloadAction<{ modality: SaludToolsModality, changes: TimeHandlerChanges }>) => {
            console.log("IM happening 2")
            switch (action.payload.modality) {
                case SaludToolsModality.Conventional: {
                    if (state.conventionalCalendarRuleCopy) {
                        state.conventionalCalendarRuleCopy.from = action.payload.changes.from
                        state.conventionalCalendarRuleCopy.to = action.payload.changes.to
                        state.conventionalCalendarRuleCopy.interval = action.payload.changes.interval
                        state.conventionalChange = true
                    }
                    break;
                }
                case SaludToolsModality.Telemedicine: {
                    if (state.telemedicineCalendarRuleCopy) {
                        state.telemedicineCalendarRuleCopy.from = action.payload.changes.from
                        state.telemedicineCalendarRuleCopy.to = action.payload.changes.to
                        state.telemedicineCalendarRuleCopy.interval = action.payload.changes.interval
                        state.telemedicineChange = true
                    }
                    break;
                }
                case SaludToolsModality.Domiciliary: {
                    if (state.domiciliaryCalendarRuleCopy) {
                        state.domiciliaryCalendarRuleCopy.from = action.payload.changes.from
                        state.domiciliaryCalendarRuleCopy.to = action.payload.changes.to
                        state.domiciliaryCalendarRuleCopy.interval = action.payload.changes.interval
                        state.domiciliaryChange = true
                    }
                    break;

                }
            }
        },
        setSimpleDateHandler: (state, action: PayloadAction<{ modality: SaludToolsModality, changes: DateHandlerSimpleChanges }>) => {
            const { id, isWeek, checkbox } = action.payload.changes
            console.log("IM happening 3")
            switch (action.payload.modality) {
                case SaludToolsModality.Conventional: {
                    if (state.conventionalCalendarRuleCopy) {
                        if (state.conventionalCalendarRuleCopy) {
                            if (isWeek) {
                                state.conventionalCalendarRuleCopy.weekdays[id as keyof WeekdaysConfiguration].isActive = checkbox;
                            } else {
                                state.conventionalCalendarRuleCopy.months[id as keyof MonthsConfiguration].isActive = checkbox;
                            }
                            state.conventionalChange = true;
                        }
                    }
                    break;
                }

                case SaludToolsModality.Telemedicine: {
                    if (state.telemedicineCalendarRuleCopy) {
                        if (isWeek) {
                            state.telemedicineCalendarRuleCopy.weekdays[id as keyof WeekdaysConfiguration].isActive = checkbox;
                        } else {
                            state.telemedicineCalendarRuleCopy.months[id as keyof MonthsConfiguration].isActive = checkbox;
                        }
                        state.telemedicineChange = true;
                    }
                    break;

                }
                case SaludToolsModality.Domiciliary: {
                    if (state.domiciliaryCalendarRuleCopy) {
                        if (isWeek) {
                            state.domiciliaryCalendarRuleCopy.weekdays[id as keyof WeekdaysConfiguration].isActive = checkbox;
                        } else {
                            state.domiciliaryCalendarRuleCopy.months[id as keyof MonthsConfiguration].isActive = checkbox;
                        }
                        state.domiciliaryChange = true;
                    }
                    break;

                }
            }
        },
        setGlobalBlockDates: (state, action: PayloadAction<{ modality: SaludToolsModality, blockDates: TimeRange[] | undefined }>) => {
            const { modality, blockDates } = action.payload
            console.log("IM happening 4")
            switch (modality) {
                case SaludToolsModality.Conventional: {
                    if (state.conventionalCalendarRuleCopy) {
                        state.conventionalCalendarRuleCopy.blockDates = blockDates;
                        state.conventionalChange = true;
                    }
                    break;
                }
                case SaludToolsModality.Telemedicine: {
                    if (state.telemedicineCalendarRuleCopy) {
                        state.telemedicineCalendarRuleCopy.blockDates = blockDates;
                        state.telemedicineChange = true;
                    }
                    break;
                }
                case SaludToolsModality.Domiciliary: {
                    if (state.domiciliaryCalendarRuleCopy) {
                        state.domiciliaryCalendarRuleCopy.blockDates = blockDates;
                        state.domiciliaryChange = true;
                    }
                    break;
                }
            }
        },
        setGlobalBlockTimes: (state, action: PayloadAction<{ modality: SaludToolsModality, blockTimes: TimeRange[] | undefined }>) => {
            const { modality, blockTimes } = action.payload
            console.log("IM happening 5")
            switch (modality) {
                case SaludToolsModality.Conventional: {
                    if (state.conventionalCalendarRuleCopy) {
                        state.conventionalCalendarRuleCopy.blockTimes = blockTimes;
                        state.conventionalChange = true;
                    }
                    break;
                }
                case SaludToolsModality.Telemedicine: {
                    if (state.telemedicineCalendarRuleCopy) {
                        state.telemedicineCalendarRuleCopy.blockTimes = blockTimes;
                        state.telemedicineChange = true;
                    }
                    break;
                }
                case SaludToolsModality.Domiciliary: {
                    if (state.domiciliaryCalendarRuleCopy) {
                        state.domiciliaryCalendarRuleCopy.blockTimes = blockTimes;
                        state.domiciliaryChange = true;
                    }
                    break;
                }
            }
        },
        setAdvanceDateHandler: (state, action: PayloadAction<{ modality: SaludToolsModality, changes: DateHandlerAdvanceChanges }>) => {
            const { dates, isWeek } = action.payload.changes
            console.log("IM happening 6")
            switch (action.payload.modality) {
                case SaludToolsModality.Conventional: {
                    if (state.conventionalCalendarRuleCopy) {
                        if (state.conventionalCalendarRuleCopy) {
                            if (isWeek) {
                                state.conventionalCalendarRuleCopy.weekdays = dates as WeekdaysConfiguration;
                            } else {
                                state.conventionalCalendarRuleCopy.months = dates as MonthsConfiguration;
                            }
                            state.conventionalChange = true;
                        }
                    }
                    break;
                }

                case SaludToolsModality.Telemedicine: {
                    if (state.telemedicineCalendarRuleCopy) {
                        if (isWeek) {
                            state.telemedicineCalendarRuleCopy.weekdays = dates as WeekdaysConfiguration;
                        } else {
                            state.telemedicineCalendarRuleCopy.months = dates as MonthsConfiguration;
                        }
                        state.telemedicineChange = true;
                    }
                    break;

                }
                case SaludToolsModality.Domiciliary: {
                    if (state.domiciliaryCalendarRuleCopy) {
                        if (isWeek) {
                            state.domiciliaryCalendarRuleCopy.weekdays = dates as WeekdaysConfiguration;
                        } else {
                            state.domiciliaryCalendarRuleCopy.months = dates as MonthsConfiguration;
                        }
                        state.domiciliaryChange = true;
                    }
                    break;

                }
            }
        },
    },
})

export const {
    setCalendarRule,
    resetCalendarRule,
    setGlobalTimeHandler,
    setSimpleDateHandler,
    setGlobalBlockDates,
    setGlobalBlockTimes,
    setAdvanceDateHandler

} = calendarRuleSlice.actions


export default calendarRuleSlice.reducer