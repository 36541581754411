import { ApiRoutes } from "../constants"
import { ApiError, ApiResponse } from "../types/Api"
import { CreateUserRequest, UpdateUserRequest, DeleteUserRequest, PasswordResetRequest } from "../types/services/UserController/request"
import PasswordChangeRequest from "../types/services/UserController/request/PasswordChangeRequest"
import { CreateUserResponse, DeleteUserResponse, GetUserFromTokensResponse, ReadUserResponse, SearchAllUsersResponse, UpdateUserResponse } from "../types/services/UserController/response"
import FindUserEmailResponse from "../types/services/UserController/response/FindUserEmailResponse"
import { ApiServices } from "./ApiServices"


/**
 * Service that will talk to Authentication UserController.
 */
class UserController extends ApiServices {

    async readUserById(userId : string) : Promise<ReadUserResponse | ApiError> {
        const request = await this.get<ReadUserResponse>(`${ApiRoutes.USER_CONTROLLER}/admin/readUserById/${userId}`) 

        return request
    }

    async createUser(createUserRequest: CreateUserRequest): Promise<CreateUserResponse | ApiError> {

        const request = await this.post<CreateUserResponse, CreateUserRequest>(`${ApiRoutes.USER_CONTROLLER}`, createUserRequest)

        return request

    }


    async updateUser(updateUserRequest: UpdateUserRequest): Promise<UpdateUserResponse | ApiError> {

        const request = await this.put<UpdateUserResponse, UpdateUserRequest>(`${ApiRoutes.USER_CONTROLLER}`, updateUserRequest)

        return request
    }

    async deleteUser(deleteUserRequest: DeleteUserRequest): Promise<DeleteUserResponse | ApiError> {

        const request = await this.delete<DeleteUserResponse, DeleteUserRequest>(`${ApiRoutes.USER_CONTROLLER}`, deleteUserRequest)

        return request
    }

    async searchAllUsers(): Promise<SearchAllUsersResponse | ApiError> {

        const request = await this.get<SearchAllUsersResponse>(`${ApiRoutes.USER_CONTROLLER}/admin/searchAllUsers`)

        return request
    }

    /**
     * Function to get a session by localStorage tokens.
     * @param createSessionRequest 
     * @returns 
     * 
     */
    async getUserFromTokens(): Promise<GetUserFromTokensResponse | ApiError> {
        
        const request = await this.get<GetUserFromTokensResponse>(`${ApiRoutes.USER_CONTROLLER}`)

        return request

    }

    /**
     * Function to find is this user already in DB.
     * @returns 
     */
    async findUserEmail(email : string): Promise<FindUserEmailResponse | ApiError> {
        
        const request = await this.get<FindUserEmailResponse>(`${ApiRoutes.USER_CONTROLLER}/findUserEmail?email=${email}`)

        return request

    }

    /**
     * Function to verify email of a user already in DB.
     * @returns 
     */
     async verifyEmail(token : string): Promise<ApiResponse | ApiError> {
        
        const request = await this.get<ApiResponse>(`${ApiRoutes.USER_CONTROLLER}/verifyEmail?token=${token}`)

        return request

    }

    /**
     * Function to request a change of password in DB.
     * @returns 
     */
    async passwordRecovery(email : string): Promise<ApiResponse | ApiError> {
        
        const request = await this.get<ApiResponse>(`${ApiRoutes.USER_CONTROLLER}/passwordRecovery?email=${email}`)

        return request

    }

    /**
     * Function to request a change of password in DB.
     * @returns 
     */
    async passwordReset(newPasswordRequest : PasswordResetRequest): Promise<ApiResponse | ApiError> {
        
        const request = await this.put<ApiResponse, PasswordResetRequest>(`${ApiRoutes.USER_CONTROLLER}/passwordReset`, newPasswordRequest)

        return request

    }

      /**
     * Function to change password in DB.
     * @returns 
     */
       async passwordChange(passwordChangeRequest : PasswordChangeRequest): Promise<ApiResponse | ApiError> {
        
        const request = await this.put<ApiResponse, PasswordChangeRequest>(`${ApiRoutes.USER_CONTROLLER}/passwordChange`, passwordChangeRequest)

        return request

    }


}

export const userController = new UserController()