import { ApiRoutes } from "../constants"
import { ApiError, ApiResponse } from "../types/Api"
import { CreateUserReviewRequest, DeleteUserReviewRequest, UpdateReviewHighlightRequest, UpdateUserReviewRequest } from "../types/services/UserReviewController/request"
import { CreateUserReviewResponse, DeleteUserReviewResponse, ReadReviewsResponse, ReadUserReviewResponse, SearchAllUsersReviewsResponse, UpdateUserReviewResponse } from "../types/services/UserReviewController/response"
import { ApiServices } from "./ApiServices"


/**
 * Service that will talk to services ReviewController.
 */
class ReviewController extends ApiServices{

    async readUserReviewById(reviewId : string) : Promise<ReadUserReviewResponse | ApiError> {
      
        const request = await this.get<ReadUserReviewResponse>(`${ApiRoutes.USER_REVIEW_CONTROLLER}/read/${reviewId}`)

        return request
    }

    async createUserReview(createUserRequest: CreateUserReviewRequest): Promise<CreateUserReviewResponse | ApiError> {

        const request = await this.post<CreateUserReviewResponse, CreateUserReviewRequest>(`${ApiRoutes.USER_REVIEW_CONTROLLER}/create`, createUserRequest)

        return request
    }

    async updateUserReview(updateUserReviewRequest: UpdateUserReviewRequest): Promise<UpdateUserReviewResponse | ApiError> {

        const request = await this.put<UpdateUserReviewResponse, UpdateUserReviewRequest>(`${ApiRoutes.USER_REVIEW_CONTROLLER}/update`, updateUserReviewRequest)
       
        return request
    }

    async deleteUserReview(deleteUserReviewRequest: DeleteUserReviewRequest): Promise<DeleteUserReviewResponse | ApiError> {

        const request = await  this.delete<DeleteUserReviewResponse, DeleteUserReviewRequest>(ApiRoutes.USER_REVIEW_CONTROLLER, deleteUserReviewRequest)
       
        return request
    }

    async searchAllUsersReviews(): Promise<SearchAllUsersReviewsResponse | ApiError> {

        const request = await this.get<SearchAllUsersReviewsResponse>(`${ApiRoutes.USER_REVIEW_CONTROLLER}/admin/searchAllUsersReviews`)
       
        return request
    }

    async updateReviewHighlight(updateReviewHighlightRequest : UpdateReviewHighlightRequest): Promise<ApiResponse | ApiError> {

        const request = await this.put<ApiResponse, UpdateReviewHighlightRequest>(`${ApiRoutes.USER_REVIEW_CONTROLLER}/admin/highlight`, updateReviewHighlightRequest)
       
        return request
    }

    async readSiteReviews(): Promise<ReadReviewsResponse | ApiError> {

        const request = await this.get<ReadReviewsResponse>(`${ApiRoutes.USER_REVIEW_CONTROLLER}/website/readReviews`)
       
        
        return request
    }
}

export const reviewController = new ReviewController()