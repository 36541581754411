const AUTH_SERVER = process.env.REACT_APP_NYD_API_AUTH!
const SERVICE_SERVER = process.env.REACT_APP_NYD_API_SERVICE!


const ApiRoutes = {
    SESSION_CONTROLLER: `${AUTH_SERVER}/session`,
    USER_CONTROLLER: `${AUTH_SERVER}/users`,
    APPOINTMENT_CONTROLLER: `${AUTH_SERVER}/appointment`,
    CREDIT_CONTROLLER: `${SERVICE_SERVER}/credit`,
    USER_REVIEW_CONTROLLER: `${SERVICE_SERVER}/review`,
    QUESTION_CONTROLLER: `${SERVICE_SERVER}/question`,
    STATISTIC_CONTROLLER: `${SERVICE_SERVER}/statistic`
}

export default ApiRoutes