import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { languages } from '../../i18n/i18n.constants'

// Define a type for the slice state
interface AppState {
    /**
     * Language defined by user.
     */
   language: string

   /**
    * Loading when getting an user.
    */
   loadingUser: boolean
}

// Define the initial state using that type
const initialState: AppState = {
    language: languages.es,
    loadingUser: true
}

export const appSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        selectLanguage: (state, action: PayloadAction<string>) => {
           state.language = action.payload
        },
        setLoadingUser: (state, action : PayloadAction<boolean>) => {
            state.loadingUser = action.payload
        }
    },
})

export const {
    selectLanguage,
    setLoadingUser
} = appSlice.actions


export default appSlice.reducer